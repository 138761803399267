<template>
  <div class="user-registeration-service-box mt-4">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="doctor-individual-form"
        @submit.prevent="handleSubmit(submit)"
      >
        <input-form
          class="mb-3 inp"
          v-model="doctorData.full_name"
          validate="required"
          name="full_name"
          placeholder="الاسم بالكامل"
          label="الاسم بالكامل"
        />
        <input-form
          class="mb-3 inp"
          v-model="doctorData.email"
          validate="required|email"
          name="email"
          placeholder="البريد الالكترونى"
          label="البريد الالكترونى"
          disabled
        />
        <country-code
          :validate="'required|numeric|min:9|max:11'"
          name="primary_phone_number"
          placeholder="رقم الهاتف"
          label="رقم الهاتف"
          v-model="doctorData.primary_phone_number"
          id="phone_number_individual"
          @onSelect="onSelect"
          disabled
        />
        <p>لتعديل رقم الهاتف أو البريد الإلكتروني برجاء التوجه  <router-link :to="{ name: 'userSettings'}"><span class="text-warning"> للملف الشخصي</span></router-link></p>
        <country-code
          :validate="'numeric|min:9|max:11'"
          name="secondary_phone_number"
          placeholder="رقم هاتف اخر"
          label="رقم هاتف اخر"
          v-model="doctorData.secondary_phone_number"
          id="secondary_phone_number_individual"
          @onSelect="onSelect2"
        />
        <input-form
          class="mb-3 inp"
          v-model="doctorData.national_id"
          :validate="{
            required: true,
            numeric: true,
            digits: 10,
            regex: /^[1-2][0-9]*$/,
          }"
          name="national_id"
          placeholder="رقم الهوية"
          label="رقم الهوية"
        />
        <!-- check-box-for-commission -->
        <b-row class="mt-4" v-if="true">
          <b-col md="12">
            <div class="d-flex align-items-center">
              <!-- Checkbox -->
              <b-form-checkbox
                name="agree"
                :value="true"
                :unchecked-value="false"
                required
                class="mr-2 mb-0"
                style="transform: scale(1.2); cursor: pointer"
              >
                ></b-form-checkbox
              >

              <!-- Text next to checkbox -->
              <span class="joining-label mb-0" style="font-size: 16px">
                أوافق على إضافة نسبة
                <strong>{{ commissionPercentage }}%</strong>
                على قيمة كل حجز
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- submit -->
        <div class="d-flex justify-content-center">
          <b-button
            v-if="!loading"
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn"
          >
            <span class="mx-2"> التالى</span>
          </b-button>
          <b-button
            v-else
            class="text-white px-5 py-2"
            variant="primary"
            disabled
          >
            <spinner-loading text="checking" />
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
/*eslint-disable*/
// import { process.env.VUE_APP_FRONT_DOCTOR_VETERINARY_LINK} from '@/config/constant'
import { core } from "@/config/pluginInit";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import api from "../../../services/join";
export default {
  data() {
    return {
      allVeterinary: [],
      commissionPercentage: 0,
      loading: false,
      doctorData: {
        full_name: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        primary_phone_number: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
        country_code: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.country_code
          : "",
        country_code2: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.country_code
          : "",
        email: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.email
          : "",
        secondary_phone_number: "",
        national_id: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.national_id
          : "",
        type: "individual",

      },
    };
  },
  methods: {
    getVeterinary() {
      marketPlace.getVeterinary().then((res) => {
        this.allVeterinary = res.data;
        console.log(",,,,,,,,,,,,,Veterinary is,,,,,,,,,,,,,,", res.data);
        let feesData = res.data.find((item) => item.key === "fees");
        console.log("feesData IS ", feesData);
        if (feesData) {
          this.commissionPercentage = feesData.value.individual_doctor_fees;
        }
      });
    },
    onSelect(data) {
      this.doctorData.country_code = data;
    },
    onSelect2(data) {
      this.doctorData.country_code2 = data;
    },
    submit() {
      this.loading = true;
      api
        .joinDoctor(this.doctorData)
        .then((res) => {
          core.showSnackbar("success", "doctor created successfully");
          window.location.href =
            process.env.VUE_APP_FRONT_DOCTOR_VETERINARY_LINK;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getVeterinary();
  },
};
</script>
