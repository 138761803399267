<template>
  <div class="w-100">
    <b-row class="justify-content-center">
      <b-col md="12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form
            class="position-relative form-top p-4"
            @submit.prevent="handleSubmit(registerVendor)"
          >
            <!-- Store Owner Information Section -->
            <b-row>
              <b-col md="12">
                <h4
                  class="my-4 position-relative joining-title line-one text-primary font-weight-bold"
                >
                  {{ $t("joining.ownerStoreInfo") }}
                </h4>
              </b-col>
            </b-row>
            <b-row class="mr-3 ml-3">
              <!-- Full Name Input -->
              <b-col md="12">
                <input-form
                  class="mb-3 joining-label"
                  v-model="vendor.full_name"
                  validate="required"
                  :name="$t('endUser.fullName')"
                  :label="$t('endUser.fullName')"
                  :placeholder="$t('endUser.fullName')"
                />
              </b-col>

              <!-- Phone Number Input -->
              <b-col md="12">
                <country-code
                  :validate="'required|numeric|min:9|max:11'"
                  :name="$t('endUser.phoneNumber')"
                  :label="$t('endUser.phoneNumber')"
                  :placeholder="$t('endUser.phoneNumber')"
                  v-model="vendor.phone"
                  id="phone_number_clinic"
                  @onSelect="onSelect"
                />
              </b-col>

              <!-- National ID Input -->
              <b-col md="12">
                <input-form
                  class="mb-3 joining-label"
                  v-model="vendor.national_id"
                  :validate="{
                    required: true,
                    numeric: true,
                    digits: 10,
                    regex: /^[1-2][0-9]*$/,
                  }"
                  :name="$t('endUser.IdNumber')"
                  :label="$t('endUser.IdNumber')"
                  :placeholder="$t('endUser.IdNumber')"
                />
              </b-col>
            </b-row>

            <!-- Store Information Section -->
            <b-row>
              <b-col md="12">
                <h4
                  class="my-4 position-relative joining-title line-two text-primary font-weight-bold"
                >
                  {{ $t("joining.storeInfo") }}
                </h4>
              </b-col>
            </b-row>
            <b-row class="ml-3 mr-3">
              <!-- Store Type (Category) Select -->
              <b-col md="12">
                <label class="joining-label">{{
                  $t("endUser.storeType")
                }}</label>
                <main-select
                  validate="required"
                  :reduce="(category) => category.id"
                  :options="allCategories"
                  v-model="vendor.category_id"
                  @change="selectedCategory"
                  label="name"
                  :placeholder="$t('endUser.storeType')"
                  dir="rtl"
                />
              </b-col>

              <!-- Store Name in Arabic Input -->
              <b-col md="6">
                <input-form
                  class="mb-3 joining-label"
                  v-model="vendor.ar_store_name"
                  validate="required"
                  :name="$t('endUser.storeNameInArabic')"
                  :label="$t('endUser.storeNameInArabic')"
                  :placeholder="$t('endUser.storeNameInArabic')"
                />
              </b-col>

              <!-- Store Name in English Input -->
              <b-col md="6">
                <input-form
                  class="mb-3 joining-label"
                  v-model="vendor.en_store_name"
                  validate="required"
                  :name="$t('endUser.storeNameInEnglish')"
                  :label="$t('endUser.storeNameInEnglish')"
                  :placeholder="$t('endUser.storeNameInEnglish')"
                />
              </b-col>

              <!-- City Select -->
              <b-col md="3">
                <label class="joining-label">{{ $t("endUser.theCity") }}</label>
                <main-select
                  class="select-city"
                  validate="required"
                  :name="$t('endUser.theCity')"
                  :placeholder="$t('endUser.theCity')"
                  :reduce="(city) => city.id"
                  :options="allCity"
                  v-model="vendor.store_city"
                  label="name"
                  dir="rtl"
                />
              </b-col>

              <!-- Store Address Input -->
              <b-col md="9">
                <input-form
                  class="mb-3 joining-label"
                  v-model="vendor.store_address"
                  validate="required"
                  :name="$t('endUser.storeAddress')"
                  :label="$t('endUser.storeAddress')"
                  :placeholder="$t('endUser.storeAddress')"
                />
              </b-col>
            </b-row>
            <!-- check-box-for-commission -->
            <b-row class="mt-4 mr-3" v-if="selected">
              <b-col md="12">
                <div class="d-flex align-items-center">
                  <!-- Checkbox -->
                  <b-form-checkbox
                    v-model="vendor.agree"
                    name="agree"
                    :value="true"
                    :unchecked-value="false"
                    required
                    class="mr-2 mb-0"
                    style="transform: scale(1.2)"
                  >
                    ></b-form-checkbox
                  >

                  <!-- Text next to checkbox -->
                  <span class="joining-label mb-0" style="font-size: 16px">
                    أوافق على إضافة نسبة
                    <strong>{{ commissionPercentage }}%</strong>
                    على قيمة كل طلب
                  </span>
                </div>
              </b-col>
            </b-row>

            <!-- Submit Button -->
            <div class="d-flex justify-content-center mt-4">
              <b-button
                class="text-white px-4 py-2"
                variant="primary"
                type="submit"
                v-if="!loadingRegistrationButton"
              >
                <span class="px-5 font-size-16">{{ $t("auth.create") }}</span>
              </b-button>
              <b-button
                v-else
                class="text-white px-5 py-2"
                variant="primary"
                disabled
              >
                <spinner-loading text="saving" />
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { core } from "@/config/pluginInit";
import joinServices from "../services/join";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import { VUE_APP_FRONT_MARKETPLACE_LINK } from "@/config/constant";

export default {
  data() {
    return {
      allVeterinary: [],
      selected: false,
      allCity: [],
      allCategories: [],
      loadingRegistrationButton: false,
      vendor: {
        full_name: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        email: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.email
          : "",
        phone: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
        country_code: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.country_code
          : "",
        national_id: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.national_id
          : "",
        ar_store_name: "",
        en_store_name: "",
        store_address: "",
        store_city: null,
        category_id: null,
      },
    };
  },
  mounted() {
    core.index();
  },
  methods: {
    selectedCategory() {
      return (this.selected = true);
    },
    registerVendor() {
      this.loadingRegistrationButton = true;
      const payload = {
        ...this.vendor,
        name: this.vendor.full_name,
      };
      joinServices
        .join(payload)
        .then((res) => {
          core.showSnackbar("success", "تم طلب متجرك بنجاح");
          window.location.href = VUE_APP_FRONT_MARKETPLACE_LINK;
        })
        .finally(() => {
          this.loadingRegistrationButton = false;
        });
    },
    getAllStoresGategories() {
      marketPlace.getAllStoresGategories().then((res) => {
        this.allCategories = res.data.data;
        return this.allCategories.pop();
      });
    },
    // getVeterinary() {
    //   marketPlace.getVeterinary().then((res) => {
    //     console.log(",,,,,,,,,,,,,Veterinary is,,,,,,,,,,,,,,", res.data);
    //     this.allVeterinary = res.data.data;
    //   });
    // },
    getAllCities() {
      marketPlace.getAllCities().then((res) => {
        this.allCity = res.data.data;
      });
    },
    onSelect(data) {
      this.vendor.country_code = data;
    },
  },
  created() {
    // this.getVeterinary();
    this.getAllStoresGategories();
    this.getAllCities();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.checkIfLogin()) {
        next({ name: "login" });
      } else next();
    });
  },
  computed: {
    commissionPercentage() {
      const selectedCategory = this.allCategories.find(
        (category) => category.id === this.vendor.category_id
      );
      return selectedCategory ? selectedCategory.commission_percentage : 0;
    },
  },
};
</script>
<style>
.joining-card {
  box-shadow: 0px 3px 31px #0000001c;
}
.joining-select {
  height: 45px !important;
  border-radius: 10px !important;
}
.joining-label {
  font-size: 20px !important;
}
.joining-label input {
  background-color: #fff !important;
}
.joining-title {
  font-size: 25px !important;
}
.line-one:after {
  position: absolute;
  width: 180px;
  right: 0;
  bottom: -4px;
  content: "";
  height: 1px;
  background: var(--iq-primary);
}
.line-two:after {
  position: absolute;
  width: 120px;
  right: 0;
  bottom: -3px;
  content: "";
  height: 1px;
  background: var(--iq-primary);
}
.vs--searchable div {
  border-radius: 10px !important;
}
.testphone {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
  margin-top: 28px;
}
.phone-input .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.vs__dropdown-option {
  font-size: 14px !important;
}
</style>
