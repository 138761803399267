var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-registeration-service-box mt-4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"doctor-individual-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":"required","name":"full_name","placeholder":"الاسم بالكامل","label":"الاسم بالكامل"},model:{value:(_vm.doctorData.full_name),callback:function ($$v) {_vm.$set(_vm.doctorData, "full_name", $$v)},expression:"doctorData.full_name"}}),_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":"required|email","name":"email","placeholder":"البريد الالكترونى","label":"البريد الالكترونى","disabled":""},model:{value:(_vm.doctorData.email),callback:function ($$v) {_vm.$set(_vm.doctorData, "email", $$v)},expression:"doctorData.email"}}),_c('country-code',{attrs:{"validate":'required|numeric|min:9|max:11',"name":"primary_phone_number","placeholder":"رقم الهاتف","label":"رقم الهاتف","id":"phone_number_individual","disabled":""},on:{"onSelect":_vm.onSelect},model:{value:(_vm.doctorData.primary_phone_number),callback:function ($$v) {_vm.$set(_vm.doctorData, "primary_phone_number", $$v)},expression:"doctorData.primary_phone_number"}}),_c('p',[_vm._v("لتعديل رقم الهاتف أو البريد الإلكتروني برجاء التوجه "),_c('router-link',{attrs:{"to":{ name: 'userSettings'}}},[_c('span',{staticClass:"text-warning"},[_vm._v(" للملف الشخصي")])])],1),_c('country-code',{attrs:{"validate":'numeric|min:9|max:11',"name":"secondary_phone_number","placeholder":"رقم هاتف اخر","label":"رقم هاتف اخر","id":"secondary_phone_number_individual"},on:{"onSelect":_vm.onSelect2},model:{value:(_vm.doctorData.secondary_phone_number),callback:function ($$v) {_vm.$set(_vm.doctorData, "secondary_phone_number", $$v)},expression:"doctorData.secondary_phone_number"}}),_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":{
          required: true,
          numeric: true,
          digits: 10,
          regex: /^[1-2][0-9]*$/,
        },"name":"national_id","placeholder":"رقم الهوية","label":"رقم الهوية"},model:{value:(_vm.doctorData.national_id),callback:function ($$v) {_vm.$set(_vm.doctorData, "national_id", $$v)},expression:"doctorData.national_id"}}),(true)?_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-2 mb-0",staticStyle:{"transform":"scale(1.2)","cursor":"pointer"},attrs:{"name":"agree","value":true,"unchecked-value":false,"required":""}},[_vm._v(" >")]),_c('span',{staticClass:"joining-label mb-0",staticStyle:{"font-size":"16px"}},[_vm._v(" أوافق على إضافة نسبة "),_c('strong',[_vm._v(_vm._s(_vm.commissionPercentage)+"%")]),_vm._v(" على قيمة كل حجز ")])],1)])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.loading)?_c('b-button',{staticClass:"px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn",attrs:{"variant":"primary","type":"submit"}},[_c('span',{staticClass:"mx-2"},[_vm._v(" التالى")])]):_c('b-button',{staticClass:"text-white px-5 py-2",attrs:{"variant":"primary","disabled":""}},[_c('spinner-loading',{attrs:{"text":"checking"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }