var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-registeration-service-box mt-4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"doctor-individual-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":"required","name":"company_name","placeholder":"اسم الشركة","label":"اسم الشركة"},model:{value:(_vm.companyData.name),callback:function ($$v) {_vm.$set(_vm.companyData, "name", $$v)},expression:"companyData.name"}}),_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":"required","name":"company_address","placeholder":"عنوان الشركة","label":"عنوان الشركة"},model:{value:(_vm.companyData.address),callback:function ($$v) {_vm.$set(_vm.companyData, "address", $$v)},expression:"companyData.address"}}),_c('input-form',{staticClass:"mb-3 inp",attrs:{"validate":"required","name":"company_owner_name","placeholder":"اسم مالك الشركة","label":"اسم مالك الشركة"},model:{value:(_vm.companyData.ownerName),callback:function ($$v) {_vm.$set(_vm.companyData, "ownerName", $$v)},expression:"companyData.ownerName"}}),_c('country-code',{attrs:{"name":"phone_number","placeholder":"رقم الهاتف","label":"رقم الهاتف","id":"phone_number_individual","validate":'required|numeric|min:9|max:11'},on:{"onSelect":_vm.onSelect},model:{value:(_vm.companyData.phone),callback:function ($$v) {_vm.$set(_vm.companyData, "phone", $$v)},expression:"companyData.phone"}}),_c('input-form',{staticClass:"mb-3 inp",attrs:{"name":"id","placeholder":"رقم الهوية","label":"رقم الهوية","validate":{
         required: true,
         numeric: true,
         digits: 10,
         regex: /^[1-2][0-9]*$/,
        }},model:{value:(_vm.companyData.ownerNationalID),callback:function ($$v) {_vm.$set(_vm.companyData, "ownerNationalID", $$v)},expression:"companyData.ownerNationalID"}}),(true)?_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-2 mb-0",staticStyle:{"transform":"scale(1.2)"},attrs:{"name":"agree","value":true,"unchecked-value":false,"required":""}},[_vm._v(" >")]),_c('span',{staticClass:"joining-label mb-0",staticStyle:{"font-size":"16px"}},[_vm._v(" أوافق على إضافة نسبة "),_c('strong',[_vm._v("10 %")]),_vm._v(" على قيمة كل طلب ")])],1)])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn",attrs:{"variant":"primary","type":"submit"}},[_c('span',{staticClass:"mx-2"},[_vm._v(" تسجيل")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }