<template>
  <div class="user-registeration-service-box mt-4">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="doctor-individual-form"
        @submit.prevent="handleSubmit(submit)"
      >
        <input-form
          class="mb-3 inp"
          v-model="clinicData.clinic_name"
          validate="required"
          name="clinic_name"
          placeholder="اسم العيادة"
          label="اسم العيادة"
        />
        <input-form
          class="mb-3 inp"
          v-model="clinicData.clinic_owner_name"
          validate="required"
          name="clinic_doctor_name"
          placeholder="إسم مالك العيادة"
          label="إسم مالك العيادة"
        />
        <input-form
          class="mb-3 inp"
          v-model="clinicData.email"
          validate="required"
          name="clinic_doctor_email"
          placeholder="البريد الالكترونى"
          label="البريد الالكترونى"
          disabled
        />
        <country-code
          :validate="'required|numeric|min:9|max:11'"
          :placeholder="$t('auth.phoneNumber')"
          :name="$t('auth.phoneNumber')"
          :label="$t('auth.phoneNumber')"
          v-model="clinicData.primary_phone_number"
          id="phone_number_clinic"
          @onSelect="onSelect"
          disabled
        />
        <p>لتعديل رقم الهاتف أو البريد الإلكتروني برجاء التوجه  <router-link :to="{name: 'userSettings'}"><span class="text-warning"> للملف الشخصي</span></router-link></p>
        <country-code
          :placeholder="$t('auth.secondary_phone_number')"
          :name="$t('auth.secondary_phone_number')"
          :label="$t('auth.secondary_phone_number')"
          v-model="clinicData.secondary_phone_number"
          id="phone_number_clinic_secondary_phone_number"
          @onSelect="onSelect2"
        />
        <input-form
          class="mb-3 inp"
          v-model="clinicData.clinic_owner_national_id"
          :validate="{
            required: true,
            numeric: true,
            digits: 10,
            regex: /^[1-2][0-9]*$/,
          }"
          name="id"
          placeholder="رقم الهوية"
          label="رقم الهوية"
          disabled
        />
        <!-- Checkbox -->
        <b-row class="mt-4" v-if="true">
          <b-col md="12">
            <div class="d-flex align-items-center">
              <b-form-checkbox
                name="agree"
                :value="true"
                :unchecked-value="false"
                required
                class="mr-2 mb-0"
                style="transform: scale(1.2)"
              >
                ></b-form-checkbox
              >

              <!-- Text next to checkbox -->
              <span class="joining-label mb-0" style="font-size: 16px">
                أوافق على إضافة نسبة
                <strong>{{ commissionPercentage }}%</strong>
                على قيمة كل طلب
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- submit -->
        <div class="d-flex justify-content-center">
          <b-button
            v-if="!loading"
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn"
          >
            <span class="mx-2"> التالى</span>
          </b-button>
          <b-button
            v-else
            class="text-white px-5 py-2"
            variant="primary"
            disabled
          >
            <spinner-loading text="checking" />
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import api from "../../../services/join";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
export default {
  data() {
    return {
      allVeterinary: [],
      commissionPercentage: 0,
      loading: false,
      clinicData: {
        clinic_name: "",
        clinic_owner_name: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        email: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.email
          : "",
        primary_phone_number: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
        secondary_phone_number: "",
        country_code: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.country_code
          : "",
        country_code2: "",
        clinic_owner_national_id: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.national_id
          : "",
      },
    };
  },
  methods: {
    getVeterinary() {
      marketPlace.getVeterinary().then((res) => {
        this.allVeterinary = res.data;
        console.log(",,,,,,,,,,,,,Veterinary is,,,,,,,,,,,,,,", res.data);
        let feesData = res.data.find((item) => item.key === "fees");
        console.log("feesData IS ", feesData);
        if (feesData) {
          this.commissionPercentage = feesData.value.clinic_fees;
        }
      });
    },
    submit() {
      this.loading = true;
      api
        .joinClinic(this.clinicData)
        .then((res) => {
          core.showSnackbar("success", "clinic created successfully");
          window.location.href =
            process.env.VUE_APP_FRONT_CLINIC_VETERINARY_LINK;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelect(data) {
      this.clinicData.country_code = data;
    },
    onSelect2(data) {
      this.clinicData.country_code2 = data;
    },
  },
  created() {
    this.getVeterinary();
  },
};
</script>
